angular.module('genericModule')
.directive('activatePiwik', [function () {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {

            $(elem).click(function () {
                _paq.push(['trackEvent', attrs.piwikCategory, attrs.piwikAction, attrs.piwikName]);
            });


        } // link
    }; // return
}]); // directive