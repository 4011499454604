angular.module('genericModule')
.controller('mainController', ['$rootScope', '$scope', '$location', '$translate', 'SloLang', 'EngLang',
function ($rootScope, $scope, $location, $translate, SloLang, EngLang) {
    $scope.getLanguage = function () {
        var lang = $location.path().split("/")[1];

        if ((lang == SloLang) || (lang == EngLang))
        {
            return lang;
        } // if
        else {
            return SloLang;
        } // else
    };


    $scope.getlanguageForUrl = function () {
        var lang = $location.path().split("/")[1];

        if ((lang == SloLang) || (lang == EngLang))
        {
            return lang + "/";
        } // if

        return "";
    };



    $scope.changeCultureUrl = function () {
        var tokens = $location.path().split("/");
        var slCultureIndex = tokens.indexOf(SloLang);
        var enCultureIndex = tokens.indexOf(EngLang);

        if (slCultureIndex > -1) {
            tokens[slCultureIndex] = EngLang;
        } // if
        else if (enCultureIndex > -1) {
            tokens.splice(enCultureIndex,1);
        } // else if
        else {
            tokens.splice(1, 0, EngLang);
        } // if

        return tokens.join("/").substring(1);
    } // changeCultureUrl


    $scope.otherCulture = function () {
        if ($rootScope.lang == SloLang) {
            return EngLang.toUpperCase();
        } // if
        else {
            return SloLang.toUpperCase();
        } // else
    }; // otherCulture


    $scope.isNumberNaN = function (num) {
        if (angular.isNumber(num) && !isNaN(num)) {
            return true;
        } // if
        return false;
    }; // isNumberNaN


    $scope.isSlovenianLanguage = function () {
        if ($rootScope.lang == SloLang) {
            return true;
        } // if
        return false;
    }; // isSlovenianLanguage


    $scope.getQueryString = function (key) {
        var search = $location.search();
        var newObject = {};
        angular.forEach(search, function (value, key) {
            newObject[key.toLowerCase()] = value;
        });
        return newObject[key];
    }; // getQueryString

    $scope.setTitle = function (key) {
        document.getElementsByTagName("title")[0].innerHTML = key;
    }
    $rootScope.lang = $scope.getLanguage();
    $translate.use($rootScope.lang);

    $scope.globalVariables = {};
}]);