angular.module('genericModule')
.directive('bootstrapPopover', [function () {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            $(elem).popover({
                trigger: 'focus',
                html: true,
                content: attrs.popoverHtml,
                placement: attrs.popoverPlacement
            });
        } // link
    }; // return
}]); // directive