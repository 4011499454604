angular.module('genericModule')
.directive('dateTimePicker', [function () {
    return {
        restrict: 'AE',
        scope: {
            language: '@',
            useCurrent: '=',
            dateTime: '=',
            location: '@'
        },
        link: function (scope, elem, attrs) {
            elem.datetimepicker({
                locale: scope.language,
                format: 'L',
                useCurrent: scope.useCurrent,
                icons: {
                    time: 'icon-clock2',
                    date: 'icon-pretekle-objave',
                    up: 'icon-arrow-up',
                    down: 'icon-arrow_down',
                    previous: 'icon-circle-left',
                    next: 'icon-circle-right',
                    today: 'icon-radio-checked',
                    clear: 'icon-bin',
                    close: 'icon-cross'
                }
            })

            //Local event change
            var datePickerInput = elem.find("input");
            datePickerInput.on('blur', function () {
                // returns moments.js format object
                var date = elem.data("DateTimePicker").date();
                if (date) {
                    scope.dateTime = elem.data("DateTimePicker").date().format("L");
                } // if
                else { 
                    scope.dateTime = null;
                } // else
                //// Global change propagation
                //$rootScope.$broadcast("emit:dateTimePicker", {
                //    location: scope.location,
                //    action: 'changed',
                //    dateTime: scope.dateTime,
                //    example: scope.useCurrent
                //});
//                scope.$apply();
            }); // elem.on 
        } // link
    }; // return
}]); // directive